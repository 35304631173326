import React from "react";
import CaseStudiesArchive from "../components/CaseStudiesArchive";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from 'src/utils/seo'

export default class BlogArchiveTemplate extends React.Component {
  render() {
    return (
        <Layout location={this.props.location}>
            <Seo pageName={`Case Studies Archive ${this.props.pageContext.tag ? `- ${this.props.pageContext.tag}` : ''}`}/> 
            <CaseStudiesArchive posts={this.props.data.posts.edges} caseStudiesNumPages={this.props.pageContext.categoryNumPages} currentPage={this.props.pageContext.currentPage} tag={this.props.pageContext.tag} tagSlug={this.props.pageContext.tagSlug}/>
        </Layout>
    );
  }
}

export const query = graphql`
    query getAllCaseStudiesTags($skip: Int!, $limit: Int!, $tag: [String]) {
        posts: allStoryblokEntry(
            limit: $limit
            skip: $skip
            filter: {
                field_component: {eq: "case-study"},
                tag_list: {in: $tag}
            }
            sort: {fields: first_published_at, order: DESC}
        ) {
            edges {
                node {
                    name
                    content
                    full_slug
                    uuid
                    first_published_at
                }
            }
        }
    }
`;
